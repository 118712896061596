import { Link } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import Content from "../../components/Content"
import HeroImage from "../../components/HeroImage"
import Layout from "../../components/Layout"

export default function Informationen() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Informationen — Schülerwohnheim Forster GmbH</title>
      </Helmet>
      <HeroImage color="#fafe66" title="Informationen" />
      <Content bgColor="#f6f49c">
        <Link to="/informationen/wohnen">Wohnen</Link>
        <br />
        <Link to="/informationen/essen">Essen</Link>
        <br />
        <Link to="/informationen/freizeit">Freizeit</Link>
        <br />
        <Link to="/informationen/anreise">Anreise</Link>
        <br />
        <Link to="/informationen/kontakt">Kontakt</Link>
      </Content>
    </Layout>
  )
}
